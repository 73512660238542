<template>
  <div class="container">
    <div class="left-side">
      <span class="separator-text">
        {{ title }}
      </span>
      <uikit-button
        type="secondary"
        @click="redirectToGoogleReviews"
      >
        Découvrez nos avis clients
      </uikit-button>
    </div>
    <div class="right-side">
      <div class="scroll-container">
        <div
          v-for="(review, index) in reviews"
          :key="review.date + review.author"
          :id="'review-' + index"
          class="card"
        >
          <div class="card-header">
            <span
              class="circle"
              :style="review.style"
            >
              {{ review.author.charAt(0) }}
              {{ review.author.split(' ')[1].charAt(0) }}
            </span>
            <div class="author">
              <div class="name">{{ review.author }}</div>
              <div class="date">{{ review.date }}</div>
            </div>
          </div>
          <div class="card-body">
            {{ review.text }}
          </div>
          <div class="card-footer">
            <div class="stars">
              <uikit-star-icon
                class="star"
                width="16px"
                height="16px"
              />
              <uikit-star-icon
                class="star"
                width="16px"
                height="16px"
              />
              <uikit-star-icon
                class="star"
                width="16px"
                height="16px"
              />
              <uikit-star-icon
                class="star"
                width="16px"
                height="16px"
              />
              <uikit-star-icon
                class="star"
                width="16px"
                height="16px"
              />
            </div>
            <div>
              <a
                class="u-color-primary-100"
                :href="review.link"
                target="_blank"
              >
                Voir l'avis sur Google
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UikitButton, UikitStarIcon } from '@hz/ui-kit'

export default {
  components: { UikitButton, UikitStarIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reviews: [
        {
          author: 'Franck R.',
          date: '11/2022',
          text: "Satisfait des différentes prestations proposées.[...] La gestion de mes logements se fait assez facilement avec l'outil. L'équipe est réactive comme on le souhaite. Je recommande.",
          link: 'https://g.co/kgs/fBY3aW',
          style: {
            'background-color': '#367588',
          },
        },
        {
          author: 'Da C.',
          date: '07/2022',
          text: '[...] La gestion de ses locataires avec les envois de quittance automatique dès réception du paiement est vraiment utile, ainsi que les alertes qui nous préviennent en cas, entre autres, de retard de paiement. Vraiment utile, ça me simplifie la vie (sans parler du temps gagné au quotidien !)',
          link: 'https://g.co/kgs/aZ9mP9',
          style: {
            'background-color': '#aa4488',
          },
        },
        {
          author: 'Marouane R.',
          date: '11/2022',
          text: "Très bonne appli. J'adore. Gérer mon immobilier n'aura jamais été aussi simple. J'en avais marre de faire les quittances chaque mois. La plateforme pense à l'essentiel et permet de penser à tout et d'être toujours dans les clous niveau légal.",
          link: 'https://g.co/kgs/tYc76A',
          style: {
            'background-color': '#97ccf0',
          },
        },
        {
          author: 'Pascale C.',
          date: '10/2022',
          text: "Première expérience de gestion locative, pour le peu que j'ai utilisé, c'est pratique, plein de fonctionnalités et assez intuitif. Super réactivité de l'équipe pour répondre aux questions.",
          link: 'https://g.co/kgs/uKgoXM\n',
          style: {
            'background-color': '#fcc200',
          },
        },
        {
          author: 'Lola P.',
          date: '07/2022',
          text: 'Je trouve le site très complet et l’accès aux différentes fonctionnalités se fait simplement, sans effort. Il apporte un vrai plus dans la gestion de la location de mon appartement.',
          link: 'https://g.co/kgs/Jf5eoD\n',
          style: {
            'background-color': '#44aa66',
          },
        },
        {
          author: 'Katleen H. ',
          date: '10/2022',
          text: 'Une plateforme super intéressante quand on est propriétaire , avec des biens en location ! Tout est centralisé , un gain de temps fou, je recommande !',
          link: 'https://g.co/kgs/n8N88L',
          style: {
            'background-color': '#5218fa',
          },
        },
        {
          author: 'Jean-Christophe S.',
          date: '08/2022',
          text: 'S’il ne faut garder qu’un seul site pour la gestion locative, c’est bien celui-ci qui part de la recherche d’idée et de marché jusqu’à la gestion des locations et du rendement des opérations.',
          link: 'https://g.co/kgs/nLF74z',
          style: {
            'background-color': '#bf00ff',
          },
        },
        {
          author: 'Dimitri S.',
          date: '07/2022',
          text: 'Outil nécessaire pour réaliser une bonne gestion locative en automatique. Je ne pourrai plus faire sans.',
          link: 'https://g.co/kgs/T483a7',
          style: {
            'background-color': '#fc8eb0',
          },
        },
      ],
    }
  },
  methods: {
    redirectToGoogleReviews() {
      window.open('https://s.horiz.io/reviews', '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.date {
  color: var(--ds-color-gray-50);
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--ds-color-primary-25);
}

.left-side {
  padding: 60px 15px;
  background-color: var(--ds-color-primary-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
}

.right-side {
  display: flex;
  padding: 52px 20px;
  gap: 64px;
  overflow: hidden;
  object-position: left;
  transition: all 20s;
  background-color: var(--ds-color-primary-25);
}

.right-side .scroll-container {
  flex-direction: row;
  display: flex;
  gap: 64px;
}

.right-side .scroll-container {
  animation: slideAnimation 70s linear infinite normal;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-play-state: running;
}

.right-side .scroll-container:hover {
  animation-play-state: paused;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 343px;
  justify-content: space-between;
  background-color: var(--ds-color-white-100);
  border-radius: 4px;
  min-height: 252px;
}

.separator-text {
  font-weight: var(--ds-weight-semi-bold);
  font-size: 24px;
  color: var(--ds-color-white-100);
  text-align: center;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .star {
    color: #ffcb00;
    margin-bottom: -5px;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: red;
  font-size: 12px;
  color: white;
  font-weight: var(--ds-weight-semi-bold);
}

.author .name {
  font-weight: 500;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2920px);
  }
}

@media @bp-desktop {
  @keyframes slideAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-3700px);
    }
  }

  .container {
    flex-direction: row;
    padding: 50px 0;
  }

  .card {
    width: 464px;
  }
  .separator-text {
    text-align: left;
    font-size: 32px;
  }
  .left-side {
    align-items: flex-start;
    padding: 60px;
  }

  .left-side,
  .right-side {
    width: 50%;
  }
}
</style>
